<style lang="less" scoped>
.top-item-row {
  margin: 10px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    width: 120px;
    white-space: nowrap;
  }
  .el-input {
    width: 300px;
    /deep/ .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
  .el-select {
    width: 300px;
  }
  .el-radio-group {
    width: 300px;
  }
  .el-cascader {
    width: 300px;
  }
}
/deep/.dialog-content__mini {
  min-width: 35rem;
}
.dialog-main {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>

<template>
  <CmDialogBorder
    size="mini"
    :zIndex="zIndex"
    :title="title + '患者病史'"
    v-show="isShow"
    @close="Close"
  >
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialog-main">
      <div class="top-item-row">
        <span>疾病名称：</span>
        <el-input v-model="hisInfo.name"></el-input>
      </div>
      <div class="top-item-row">
        <span>首次诊断日期：</span>
        <el-date-picker
          v-model="hisInfo.firstDiagnosisDate"
          type="date"
          align="center"
          size="mini"
          placeholder="首次诊断日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
      <div class="top-item-row">
        <span>首次诊断方式：</span>
        <el-input v-model="hisInfo.firstDiagnosisWay"></el-input>
      </div>
      <div class="top-item-row">
        <span>有无病理诊断：</span>
        <el-radio-group
          v-model="hisInfo.hasPathologicDiagnosis"
          @change="getHasPathologicDiagnosis"
          style="margin-top: 5px"
        >
          <el-radio label="有">有</el-radio>
          <el-radio label="无">无</el-radio>
        </el-radio-group>
      </div>
      <div class="top-item-row" v-show="hisInfo.hasPathologicDiagnosis === '有'">
        <span>病理诊断日期：</span>
        <el-date-picker
          v-model="hisInfo.pathologicDiagnosisDate"
          type="date"
          align="center"
          size="mini"
          placeholder="病理诊断日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
      <div class="top-item-row" v-show="hisInfo.hasPathologicDiagnosis === '有'">
        <span>病理诊断结果：</span>
        <el-input v-model="hisInfo.pathologicDiagnosisResult"></el-input>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'

import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../../../plugins/clone'

export default {
  name: 'addMedicalHisDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      patientIndex: null,
      title: '',
      hisInfo: {
        patientIndex: null,
        name: '',
        firstDiagnosisDate: '',
        firstDiagnosisWay: '',
        hasPathologicDiagnosis: '无',
        pathologicDiagnosisDate: '',
        pathologicDiagnosisResult: ''
      }
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    getHasPathologicDiagnosis(val) {
      if (val === '无') {
        this.hisInfo.pathologicDiagnosisDate = ''
        this.hisInfo.pathologicDiagnosisResult = ''
      }
    },
    Show(val) {
      if (val) {
        this.title = '编辑'
        this.hisInfo = deepClone(val)
      }
      this.patientIndex = sessionStorage.getItem('patientIndex')

      this.isShow = true
    },

    Save() {
      if (!this.hisInfo.name) {
        return this.$message.warning('请填写疾病名称')
      }
      let data = {
        name: this.hisInfo.name,
        firstDiagnosisDate: this.hisInfo.firstDiagnosisDate,
        firstDiagnosisWay: this.hisInfo.firstDiagnosisWay,
        hasPathologicDiagnosis: this.hisInfo.hasPathologicDiagnosis,
        pathologicDiagnosisDate: this.hisInfo.pathologicDiagnosisDate,
        pathologicDiagnosisResult: this.hisInfo.pathologicDiagnosisResult
      }
      if (this.hisInfo.patientIndex) {
        data.patientIndex = this.hisInfo.patientIndex
        data.uuid = this.hisInfo.uuid
        this.$api
          .post(`/v1/webconsole/patientCenter/disease/update`, data)
          .then((res) => {
            if (res.data && res.data.data) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.$emit('save')
            }
          })
          .finally(() => {
            this.Close()
          })
      } else {
        this.$api
          .post(`/v1/webconsole/patientCenter/disease/add/${this.patientIndex}`, data)
          .then((res) => {
            if (res.data && res.data.data) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$emit('save')
            }
          })
          .finally(() => {
            this.Close()
          })
      }
    },
    Close() {
      this.title = '新增'
      this.hisInfo = {
        patientIndex: null,
        name: '',
        firstDiagnosisDate: '',
        firstDiagnosisWay: '',
        hasPathologicDiagnosis: '无',
        pathologicDiagnosisDate: '',
        pathologicDiagnosisResult: ''
      }
      this.isShow = false
    }
  }
}
</script>
