<style scoped lang="less">
.input-form-item-content {
  width: 180px;
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .input-form-item-content {
    width: 120px;
  }
}
.input-form-item-textContent {
  width: 400px;
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .input-form-item-textContent {
    width: 300px;
  }
}
input {
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #02090c;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  padding: 0 10px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__icon:before {
  content: '';
}

.input-form::-webkit-scrollbar {
  width: 12px;
}
.input-form::-webkit-scrollbar-track {
  border-radius: 10px;
}
.input-form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.input-form::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.input-form {
  flex: 1;
  overflow: auto;
  background: #f2f2f2;
  .el-descriptions {
    margin: 10px 25px;
  }
  .box-card {
    margin: 10px;
    /deep/.el-card__header {
      padding: 10px 20px 5px;
    }
    .clearfix {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }
  }
}

.el-table {
  border: 1px solid #b4b6ba;
  margin-top: 5px;
}

.pwrap {
  display: flex;
  align-items: center;
  .info-address {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}

.pwrap /deep/ .distpicker-address-wrapper select {
  padding: 0;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
}
</style>
<style>
.my-label {
  min-width: 100px !important;
  height: 30px;
  line-height: 30px !important;
}
</style>
<template>
  <div
    class="main-content"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div class="input-form">
      <TimeLine :info="info" />
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>病人信息 {{ solution.completion + '%' }}</span>

          <div>
            <el-button
              v-if="isEdit"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              class="commonBtn"
              @click="editClick"
              :disabled="signed_status"
            >
              编辑
            </el-button>
            <div v-else>
              <el-button
                type="success"
                size="mini"
                icon="el-icon-edit"
                class="commonBtn"
                @click="saveClick"
                :disabled="signed_status"
              >
                保存
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-sort"
                class="commonBtn"
                @click="synchronousClick"
                :disabled="signed_status"
              >
                同步
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                class="commonBtn"
                @click="closeClick"
                :disabled="signed_status"
              >
                取消
              </el-button>
            </div>
          </div>
        </div>
        <el-descriptions :column="columnNum" :colon="false">
          <el-descriptions-item label="姓名:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsName">
                {{ solution.patientsName }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.patientsName"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="病案号:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsID">
                {{ solution.patientsID }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.patientsID"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="性别:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsSex">
                {{ solution.patientsSex }}
              </el-tag>
            </div>
            <el-radio-group v-model="solutionInfo.patientsSex" style="margin: 5px 0 5px 0" v-else>
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-descriptions-item>

          <el-descriptions-item label="年龄:" label-class-name="my-label">
            <div>
              <el-tag type="info" v-show="solution.ageDays">
                {{ calculateAgeFromDays(solution.ageDays) }}
              </el-tag>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="身份证号:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsCertificate">
                {{ solution.patientsCertificate }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.patientsCertificate"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="身高:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsHeight">
                {{ solution.patientsHeight + 'cm' }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.patientsHeight"
              size="mini"
              v-else
            >
              <template slot="append">cm</template>
            </el-input>
          </el-descriptions-item>
          <el-descriptions-item label="体重:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsWeight">
                {{ solution.patientsWeight + 'kg' }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.patientsWeight"
              size="mini"
              v-else
            >
              <template slot="append">kg</template>
            </el-input>
          </el-descriptions-item>
          <el-descriptions-item label="婚姻状况:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.marry">
                {{ solution.marry }}
              </el-tag>
            </div>
            <cm-select
              class="input-form-item-content"
              v-model="solutionInfo.marry"
              activeUrl="/v1/webconsole/comboData/get/PUB_maritalStatus"
              v-else
            ></cm-select>
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="省/市/县" label-class-name="my-label">
            <div class="pwrap">
              <div v-if="isEdit">
                <el-tag type="info" v-show="solution.province">
                  {{ solution.province }}
                  <span v-if="solution.city">/</span>
                  {{ solution.city }}
                  <span v-if="solution.county">/</span>
                  {{ solution.county }}
                </el-tag>
              </div>
              <v-distpicker
                :province="solutionInfo.province"
                :city="solutionInfo.city"
                :area="solutionInfo.county"
                @selected="onSelected"
                style="display: flex; padding: 0; height: 28px; font-size: 14px; line-height: 28px"
                v-else
              ></v-distpicker>
              <!-- <div class="info-address">
                <span>家庭住址：</span>
                <div v-if="isEdit">
                  <el-tag type="info" v-show="solution.patientsAddress">
                    {{ solution.patientsAddress }}
                  </el-tag>
                </div>
                <el-input
                  class="input-form-item-content"
                  type="text"
                  v-model="solutionInfo.patientsAddress"
                  size="mini"
                  v-else
                ></el-input>
              </div> -->
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="家庭住址:" :span="2" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsAddress">
                {{ solution.patientsAddress }}
              </el-tag>
            </div>
            <el-input
              style="width: 80%"
              class="input-form-item-content"
              type="textarea"
              v-model="solutionInfo.patientsAddress"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="文化程度:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.eduLevel">
                {{ solution.eduLevel }}
              </el-tag>
            </div>
            <cm-select
              style="margin-top: 5px"
              class="input-form-item-content"
              v-model="solutionInfo.eduLevel"
              activeUrl="/v1/webconsole/comboData/get/ssjbxx_whcd"
              v-else
            ></cm-select>
          </el-descriptions-item>

          <el-descriptions-item label="民族:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.ethnicity">
                {{ solution.ethnicity }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.ethnicity"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>

          <el-descriptions-item label="本人电话:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.patientsPhone">
                {{ solution.patientsPhone }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.patientsPhone"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="联系人:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.contactName">
                {{ solution.contactName }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.contactName"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="联系人电话:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.contactPhone">
                {{ solution.contactPhone }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.contactPhone"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label"></el-descriptions-item>
          <el-descriptions-item label-class-name="my-label"></el-descriptions-item>
          <el-descriptions-item label-class-name="my-label"></el-descriptions-item>
          <el-descriptions-item label="是否饮酒:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.isAlcohol">
                {{ solution.isAlcohol }}
              </el-tag>
            </div>
            <el-radio-group v-model="solutionInfo.isAlcohol" style="margin: 5px 0 5px 0" v-else>
              <el-radio label="是">是</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group>
          </el-descriptions-item>
          <el-descriptions-item label="酒龄:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.alcoholYears">
                {{ solution.alcoholYears + '年' }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.alcoholYears"
              size="mini"
              v-else
            >
              <template slot="append">年</template>
            </el-input>
          </el-descriptions-item>
          <el-descriptions-item label="酒精日摄入量:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.alcoholDaily">
                {{ solution.alcoholDaily + 'ml' }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.alcoholDaily"
              size="mini"
              v-else
            >
              <template slot="append">ml</template>
            </el-input>
          </el-descriptions-item>
          <el-descriptions-item label="" label-class-name="my-label"></el-descriptions-item>
          <el-descriptions-item label="是否吸烟:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.isSmoke">
                {{ solution.isSmoke }}
              </el-tag>
            </div>
            <el-radio-group v-model="solutionInfo.isSmoke" style="margin: 5px 0 5px 0" v-else>
              <el-radio label="是">是</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group>
          </el-descriptions-item>
          <el-descriptions-item label="烟龄:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.smokeYears">
                {{ solution.smokeYears + '年' }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.smokeYears"
              size="mini"
              v-else
            >
              <template slot="append">年</template>
            </el-input>
          </el-descriptions-item>
          <el-descriptions-item label="日摄入量:" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.smokeDaily">
                {{ solution.smokeDaily + '支' }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="text"
              v-model="solutionInfo.smokeDaily"
              size="mini"
              v-else
            >
              <template slot="append">支</template>
            </el-input>
          </el-descriptions-item>
          <el-descriptions-item label="" label-class-name="my-label"></el-descriptions-item>
          <el-descriptions-item label="既往病史:" :span="2" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.pastHistory">
                {{ solution.pastHistory }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              style="width: 80%"
              placeholder="请输入"
              type="textarea"
              v-model="solutionInfo.pastHistory"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="手术史:" :span="2" label-class-name="my-label">
            <div v-if="isEdit">
              <el-tag type="info" v-show="solution.operationHistory">
                {{ solution.operationHistory }}
              </el-tag>
            </div>
            <el-input
              class="input-form-item-content"
              style="width: 80%"
              placeholder="请输入"
              type="textarea"
              v-model="solutionInfo.operationHistory"
              size="mini"
              v-else
            ></el-input>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <!-- <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>病史</span>
          <div>
            <el-button type="primary" size="mini" icon="el-icon-plus" @click="addMedicalHis">
              新增
            </el-button>
          </div>
        </div>
        <div>
          <el-table border :data="medicalHisData" style="width: 100%">
            <el-table-column align="center" prop="name" label="疾病名称"></el-table-column>
            <el-table-column
              align="center"
              prop="firstDiagnosisDate"
              label="首次诊断日期"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="firstDiagnosisWay"
              label="首次诊断方式"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="hasPathologicDiagnosis"
              label="有无病理例诊断"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="pathologicDiagnosisDate"
              label="病理诊断日期"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="pathologicDiagnosisResult"
              label="病理诊断结果"
            ></el-table-column>
            <el-table-column align="center" width="100" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit_medicalHis(scope.row)">
                  <div>编辑</div>
                </el-button>
                <el-button
                  type="text"
                  style="color: red"
                  size="small"
                  @click="delete_medicalHis(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card> -->
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>家族史</span>
          <div>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              class="commonBtn"
              @click="addFamilyHis"
            >
              新增
            </el-button>
          </div>
        </div>
        <div>
          <el-table border :data="familyHisData" style="width: 100%">
            <el-table-column align="center" prop="name" label="患者患病名称"></el-table-column>
            <el-table-column
              align="center"
              prop="relationship"
              label="家族患者与本人关系"
            ></el-table-column>
            <el-table-column align="center" width="100" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit_familyHis(scope.row)">
                  <div>编辑</div>
                </el-button>
                <el-button
                  type="text"
                  style="color: red"
                  size="small"
                  @click="delete_familyHis(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>既往手术史</span>
          <div>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              class="commonBtn"
              @click="addOperationHis"
            >
              新增
            </el-button>
          </div>
        </div>
        <div>
          <el-table border :data="operationHisData" style="width: 100%">
            <el-table-column align="center" prop="operation" label="手术名称"></el-table-column>
            <el-table-column align="center" prop="operationDate" label="手术日期"></el-table-column>
            <el-table-column align="center" width="100" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit_operationHis(scope.row)">
                  <div>编辑</div>
                </el-button>
                <el-button
                  type="text"
                  style="color: red"
                  size="small"
                  @click="delete_operationHis(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <FamilyHisDialog ref="addFamilyHisDialog" @save="familyHis_dialogSave"></FamilyHisDialog>
    <OperationHisDialog
      ref="addOperationHisDialog"
      @save="operationHis_dialogSave"
    ></OperationHisDialog>
  </div>
</template>

<script>
import deepClone from '@/plugins/clone'
import CmSelect from '@/components/CmSelect.vue'
import addFamilyHisDialog from './components/addFamilyHisDialog.vue'
import addMedicalHisDialog from './components/addMedicalHisDialog.vue'
import addOperationHisDialog from './components/addOperationHisDialog.vue'
import TimeLine from '../../components/TimeLine'
import { calculateAgeFromDays } from '../../../../../utils'

import VDistpicker from 'v-distpicker'

export default {
  name: 'PatientInfo',
  components: {
    FamilyHisDialog: addFamilyHisDialog,
    MedicalHisDialog: addMedicalHisDialog,
    OperationHisDialog: addOperationHisDialog,
    CmSelect,
    VDistpicker,
    TimeLine
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    solutionId: {
      type: String
    }
  },
  data() {
    return {
      isEdit: true,
      columnNum: 4,
      signed_status: false,
      loading: false,
      operationHisData: [],
      familyHisData: [],
      medicalHisData: [],
      solutionInfo: {},
      solution: {}
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.patientsIndex) {
          this.getInfo()
          this.getOperationHisTable()
          this.getFamilyHisTable()
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    calculateAgeFromDays,
    onSelected(data) {
      this.solutionInfo.province = data.province.value
      this.solutionInfo.city = data.city.value
      this.solutionInfo.county = data.area.value
    },
    addFamilyHis() {
      this.$refs.addFamilyHisDialog.Show()
    },

    addMedicalHis() {
      this.$refs.addMedicalHisDialog.Show()
    },

    addOperationHis() {
      this.$refs.addOperationHisDialog.Show()
    },

    familyHis_dialogSave() {
      this.getFamilyHisTable()
    },
    operationHis_dialogSave() {
      this.getOperationHisTable()
    },

    edit_medicalHis(row) {
      this.$refs.addMedicalHisDialog.Show(row)
    },

    delete_medicalHis(row) {
      this.$api.delete(`/v1/webconsole/patientCenter/disease/delete/${row.uuid}`).then((res) => {
        if (res.data && res.data.status === 200) {
          return this.$message.success('删除成功')
        }
      })
    },

    edit_familyHis(row) {
      this.$refs.addFamilyHisDialog.Show(row)
    },

    delete_familyHis(row) {
      this.$api.delete(`/v1/webconsole/patientCenter/family/delete/${row.uuid}`).then((res) => {
        if (res.data && res.data.status === 200) {
          this.getFamilyHisTable()
          return this.$message.success('删除成功')
        }
      })
    },

    edit_operationHis(row) {
      this.$refs.addOperationHisDialog.Show(row)
    },

    delete_operationHis(row) {
      this.$api
        .delete(`/v1/webconsole/patientCenter/operationHistory/delete/${row.uuid}`)
        .then((res) => {
          if (res.data && res.data.status === 200) {
            this.getOperationHisTable()
            return this.$message.success('删除成功')
          }
        })
    },

    getOperationHisTable() {
      this.$api
        .get(`/v1/webconsole/patientCenter/operationHistory/list/${this.info.patientsIndex}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.operationHisData = res.data.data
          }
        })
    },
    getFamilyHisTable() {
      this.$api
        .get(`/v1/webconsole/patientCenter/family/list/${this.info.patientsIndex}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.familyHisData = res.data.data
          }
        })
    },
    getMedicalHisTable() {
      this.$api
        .get(`/v1/webconsole/patientCenter/disease/list/${this.info.patientsIndex}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.medicalHisData = res.data.data
          }
        })
    },
    getInfo() {
      this.$api
        .get(`/v1/webconsole/patientCenter/patient/${this.info.patientsIndex}`)
        .then((res) => {
          this.solution = res.data.data
        })
    },

    synchronousClick() {
      if (this.solution.patientsID) {
        this.$api
          .get(`/v1/webconsole/patientCenter/patient/his/${this.solution.patientsID}`)
          .then((res) => {
            if (res.data.status === 200 && res.data.data !== null) {
              this.solutionInfo = res.data.data
            } else {
              this.$message.error(res.data.msg)
            }
          })
      } else {
        this.$message.error('请输入患者病案号')
      }
    },
    editClick() {
      this.isEdit = false
      this.solutionInfo = deepClone(this.solution)
      if (this.solutionInfo.firstDiagnosisDate) {
        this.solutionInfo.firstDiagnosisDate = new Date(this.solutionInfo.firstDiagnosisDate)
      }
      if (this.solutionInfo.pathologicDiagnosisDate) {
        this.solutionInfo.pathologicDiagnosisDate = new Date(
          this.solutionInfo.pathologicDiagnosisDate
        )
      }
      if (this.solutionInfo.operation1DateHistory) {
        this.solutionInfo.operation1DateHistory = new Date(this.solutionInfo.operation1DateHistory)
      }
      if (this.solutionInfo.operation2DateHistory) {
        this.solutionInfo.operation2DateHistory = new Date(this.solutionInfo.operation2DateHistory)
      }
    },

    saveClick() {
      this.loading = true
      this.$api
        .post(
          `/v1/webconsole/patientCenter/patient/update/${this.info.patientsIndex}`,
          this.solutionInfo
        )
        .then(() => {
          this.getInfo()
          this.loading = false
          return this.$message.success('保存成功')
        })

      this.isEdit = true
    },
    closeClick() {
      this.isEdit = true
    }
  }
}
</script>
