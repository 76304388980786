<style scoped>
.el-menu-item.is-active {
  border-left: 3px #75affd solid;
}
</style>
<style lang="less">
.add-form {
  .el-dialog {
    width: 1000px !important;
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }

    .el-dialog__body {
      height: 600px;
      overflow-y: auto;
      padding: 0;

      .dialog-btn-wrap {
        height: 50px;
        background-color: #f2f2f2;
        z-index: 100;
        display: flex;
        align-items: center;
        padding-left: 20px;
      }
    }
    .form-wrap {
      margin-top: 20px;
      padding-left: 20px;
      .label-item {
        line-height: 35px;
        .el-radio {
          height: 30px;
          line-height: 5px;
          margin: 0 5px 0 0;

          .el-radio__inner {
            display: none;
          }
        }
      }
      .select-wrap {
        .el-col {
          margin-bottom: 15px;
          .el-button {
            margin-left: 10px;
          }
        }
      }
      .el-divider__text {
        background-color: #f8f8f8;
      }
      .el-row {
        margin-bottom: 15px;
      }
      .el-input {
        width: 250px;
      }
      .el-input__inner {
        border-color: #dcdfe6;
        height: 35px;
      }
      .next {
        margin-bottom: 15px;
      }
      .el-tabs__header {
        position: fixed;
        z-index: 100;
        background: #fff;
      }
      .fixed-btn {
        margin-top: 40px;
        .el-button {
          position: fixed;
          z-index: 500;
        }
      }
      .el-tabs__content {
        .container {
          margin-top: 80px;
        }
      }
    }
  }
}
</style>
<style scoped lang="less">
.addMenuTitle {
  padding-left: 40px;
  .el-button {
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    span {
      display: flex;
      align-items: center;
    }
  }
}
</style>

<template>
  <el-menu
    :default-active="menuPath"
    :default-openeds="openeds"
    @select="menuSelect"
    class="el-menu-vertical-demo"
    text-color="#000"
    active-text-color="#409EFF"
  >
    <el-menu-item index="/Treatment/Info">
      <i class="el-icon-menu"></i>
      <span>方案概览</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Details">
      <i class="el-icon-menu"></i>
      <span>基本情况</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Check">
      <i class="el-icon-menu"></i>
      <span>基线 {{ averageNum ? averageNum + '%' : '' }}</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Inspection">
      <i class="el-icon-menu"></i>
      <span>检查检验</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Dicom">
      <i class="el-icon-menu"></i>
      <span>PACS影像</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Disposal">
      <i class="el-icon-menu"></i>
      <span>拟治疗措施</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Evaluate">
      <i class="el-icon-menu"></i>
      <span>评价</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Medication">
      <i class="el-icon-menu"></i>
      <span>医嘱</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/Progression">
      <i class="el-icon-menu"></i>
      <span>病情进展</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/AdverseEvents">
      <i class="el-icon-menu"></i>
      <span>不良反应</span>
    </el-menu-item>
    <el-menu-item index="/Treatment/DeathEvents">
      <i class="el-icon-menu"></i>
      <span>死亡事件</span>
    </el-menu-item>
    <el-submenu index="/Treatment/Follow">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span>随访</span>
      </template>
      <div class="addMenuTitle">
        <el-button type="primary" size="mini" @click="addFollow" class="commonBtn">
          <i class="el-icon-circle-plus-outline" style="margin: 0"></i>
          新增随访
        </el-button>
      </div>
      <div v-for="(item, index) in followList" :key="index">
        <el-menu-item
          :index="
            '/Treatment/Follow' + `?followId=${item.followId}&idx=${submenuIdx}&id=${submenuId}`
          "
        >
          <span slot="title">
            {{ item.followTitle }}
          </span>
        </el-menu-item>
      </div>
    </el-submenu>
    <el-dialog title="新增随访" class="add-form" :visible.sync="dialogFormVisible" width="20%">
      <div class="dialog-btn-wrap">
        <el-button type="primary" size="mini" @click="saveNextFollow">新增</el-button>
      </div>
      <div class="form-wrap">
        <div class="select-wrap">
          <el-row type="flex" align="middle">
            <el-col :span="5">随访标题</el-col>
            <el-col :span="14">
              <el-input v-model="followTitle" placeholder="请输入随访标题"></el-input>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="5">随访方式</el-col>
            <el-col :span="14">
              <el-radio-group v-model="followType" class="label-item">
                <el-radio
                  border
                  v-for="(item, index) in followTypeList"
                  :key="index"
                  :label="item.comboValue"
                >
                  {{ item.comboValue }}
                </el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="5">随访时间</el-col>
            <el-col :span="14">
              <el-date-picker
                v-model="followDate"
                type="date"
                align="center"
                size="mini"
                unlink-panels
                placeholder="随访时间"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              ></el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5" style="margin: 0">随访表单</el-col>
            <el-col :span="14">
              <el-row>
                <el-col :span="24">
                  <el-checkbox-group v-model="followTemplateName">
                    <el-checkbox
                      v-for="(item, index) in followTemplate"
                      :key="index"
                      :label="item.formId"
                    >
                      {{ item.alias }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </el-menu>
</template>

<script>
export default {
  name: 'Menu',
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    averageNum: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      followList: [],
      openeds: ['/Treatment/Follow'],
      dialogFormVisible: false,
      followTemplate: [], // 随访模板
      followTemplateName: [], // 随访模板名称
      followDate: '', // 随访时间
      followTitle: '', // 随访标题
      followType: '', //随访方式
      followTypeList: [], //随访方式列表

      menuPath: this.$route.path,
      submenuIdx: this.$route.query.idx,
      submenuId: this.$route.query.id
    }
  },
  watch: {
    '$route.path'(val) {
      if (val.includes('/Treatment/Follow')) {
        this.menuPath =
          '/Treatment/Follow' +
          `?followId=${this.$route.query.followId}` +
          `&idx=${this.$route.query.idx}&id=${this.$route.query.id}`
      } else {
        this.menuPath = val
      }
    },
    '$route.query.id'(val) {
      if (val) {
        this.getFollowType()
        this.getSubmenu()
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getFollowType()
      this.getSubmenu()
    }
  },

  beforeDestroy() {},
  methods: {
    // 随访方式
    getFollowType() {
      this.$api.get(`/v1/webconsole/comboData/get/FOLLOW_follow_type`).then(({ data: res }) => {
        this.followTypeList = res.data
      })
    },
    getSubmenu() {
      this.followList = []
      this.submenuId = this.$route.query.id
      this.submenuIdx = this.$route.query.idx
      this.$api
        .get(`/v1/webconsole/follow/list/follows/solution/${this.$route.query.id}`)
        .then((res) => {
          if (res.data && res.data.data && res.data.data.records.length > 0) {
            this.followList = res.data.data.records
            if (this.$route.path.includes('/Treatment/Follow')) {
              this.menuPath =
                '/Treatment/Follow' +
                `?followId=${this.$route.query.followId}` +
                `&idx=${this.$route.query.idx}&id=${this.$route.query.id}`
            }
          }
        })
    },

    // 保存临时随访
    saveNextFollow() {
      if (!this.followTitle) {
        this.$alert('请填写随访标题', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!this.followDate) {
        this.$alert('请选择随访时间', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!this.followType) {
        this.$alert('请选择随访方式', '提示', {
          confirmButtonText: '确定'
        })
        return
      }

      let url = ''
      url = `/v1/webconsole/follow/add/solution/${this.info.id}?title=${
        this.followTitle
      }&followDate=${this.dayjs(this.followDate).format('YYYY-MM-DD HH:mm:ss')}&followType=${
        this.followType
      }`

      let data = []

      this.followTemplate.forEach((item) => {
        this.followTemplateName.forEach((ele) => {
          if (item.formId === ele) {
            data.push(item)
          }
        })
      })

      this.$api.post(url, data).then((res) => {
        if (res.data && res.data.data) {
          this.dialogFormVisible = false
          this.$message({
            type: 'success',
            message: '新增成功'
          })
          this.getSubmenu()
        }
      })
    },

    addFollow() {
      this.followDate = this.dayjs().format('YYYY-MM-DD')
      this.followTitle = ''
      this.followType = ''
      this.followTemplate = []
      this.followTemplateName = []
      this.getFollowTemplate()
      this.dialogFormVisible = true
    },

    getFollowTemplate() {
      this.$api
        .get(`/v1/webconsole/eform/solution/form/${this.info.diseaseModule}/SFJL`)
        .then(({ data: res }) => {
          this.followTemplate = res.data
          this.followTemplate.forEach((item) => {
            this.followTemplateName.push(item.formId)
          })
        })
    },

    menuSelect(i) {
      const query = this.$route.query
      if (query.idx) {
        if (i.includes('/Treatment/Follow')) {
          this.$router.push(`${i}`)
        } else {
          this.$router.push(`${i}?idx=${query.idx}&id=${query.id}`)
        }
      } else {
        this.$router.push(`${i}`)
      }
    }
  }
}
</script>
