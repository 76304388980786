<template>
  <div v-loading="loading">
    <div class="header">
      <div class="info">
        <div class="user">
          <div class="user-avator">
            <img src="../../../assets/img/doctor.png" />
          </div>
        </div>
        <div class="info-above" v-if="patientInfo">
          <span>{{ patientInfo.patientsName }}</span>
          <span>{{ patientInfo.patientsSex }}</span>
          <span v-if="patientInfo?.ageDays">
            {{ calculateAgeFromDays(patientInfo.ageDays) }}&ensp;
          </span>
          <span>{{ patientInfo.patientsID }}</span>
        </div>
      </div>
      <div class="info-button">
        <template v-for="item in moduleList">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-document-add"
            class="commonBtn"
            @click="addSolution(item)"
          >
            新建{{ item.title }}方案
          </el-button>
        </template>
        <!-- <el-button type="primary" icon="el-icon-phone-outline" size="mini" @click="addFollow">
          新建随访
        </el-button> -->
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-picture"
          class="commonBtn"
          @click="goto_image"
        >
          PACS影像
        </el-button>
      </div>
    </div>
    <div class="block">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="患者信息">
          <span slot="label">
            <div class="info-label">患者信息</div>
          </span>
          <PatientInfo :info="patientInfo" />
        </el-tab-pane>
        <el-tab-pane v-for="(item, key) in planList" :key="key" :name="key">
          <span slot="label">
            <el-dropdown @command="handleCommand" trigger="click">
              <span class="el-dropdown-link">
                <span v-if="solutionInfo && solutionInfo.typeCategory === key">
                  {{ solutionInfo.typeCategory + '-' + solutionInfo.label }}
                </span>
                <span v-else>{{ key }}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="ele" v-for="ele in item" :key="ele.id">
                  <span :style="ele.isShow ? 'color: #75affd' : ''">
                    {{ ele.label }}
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <div class="list-main">
            <DetailSolution
              v-if="solutionId && solutionInfo && key === solutionInfo.typeCategory"
              :solutionInfo="solutionInfo"
              :solutionId="solutionId"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <add-solution-dialog ref="addSolutionDialog" @save="_dialogSave"></add-solution-dialog>
    <!-- 随访 -->
    <!-- <add-follow-dialog
      ref="AddFollowDialog"
      :info="patientNewInfo"
      @save="_followDialogSave"
    ></add-follow-dialog> -->
  </div>
</template>
<script>
// import AddFollowDialog from '../DetailSolution/components/Follow/components/AddFollowDialog'
import PatientInfo from '../DetailSolution/components/PatientInfo'
import DetailSolution from '../DetailSolution/index.vue'
import addSolutionDialog from './components/addSolutionDialog'
export default {
  name: 'Treatment',
  components: {
    PatientInfo,
    DetailSolution,
    addSolutionDialog
    // AddFollowDialog
  },
  data() {
    return {
      activeName: '患者信息',
      planList: null, // 方案列表
      patientInfo: null, // 顶部个人信息
      moduleList: [], // 病种列表
      followList: [],
      operationInfoList: [],
      moudle: '', // 病种
      patientIndex: '',
      loading: false,
      solutionId: '',
      solutionInfo: null,
      patientNewInfo: null
    }
  },
  created() {
    this.getPlanList()
    this.getModule()
  },
  mounted() {
    this.getPatientInfo()
    this.$bus.$on('getPlanList', () => {
      this.getPlanList()
    })
  },
  beforeDestroy() {
    this.$bus.$off('getPlanList')
  },
  methods: {
    calculateAgeFromDays(days) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()

      const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
      const birthYear = birthDate.getFullYear()

      let age = currentYear - birthYear

      // 检查生日是否已经过了，如果还没过，则减去一年
      if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
        age--
      }

      if (age < 1) {
        return days + '天'
      } else if (age < 2) {
        const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
        return '1年零' + remainingDays + '天'
      } else {
        return age + '岁'
      }
    },

    handleClick() {
      if (this.activeName !== '患者信息') {
        if (this.$route.query.idx !== this.activeName) {
          for (let i in this.planList) {
            this.planList[i].forEach((element) => {
              element.isShow = false
            })
          }
          this.planList[this.activeName][0].isShow = true
          this.solutionId = this.planList[this.activeName][0].id
          this.solutionInfo = this.planList[this.activeName][0]
          this.$router.push({
            path: `/Treatment/Info?idx=${this.activeName}&id=${this.solutionId}`
          })
        }
      } else {
        this.$router.push({ path: `/Treatment` })
        this.solutionId = ''
      }
    },
    addSolution(val) {
      this.$refs.addSolutionDialog.Show(val)
    },
    _dialogSave() {
      this.activeName = this.$route.query.idx || '患者信息'
      this.getPlanList()
    },

    handleCommand(command) {
      for (let i in this.planList) {
        this.planList[i].forEach((element) => {
          if (element.id === command.id) {
            element.isShow = true
          } else {
            element.isShow = false
          }
        })
      }
      this.solutionId = command.id
      this.solutionInfo = command

      this.$router.push({ path: `/Treatment/Info?idx=${this.activeName}&id=${this.solutionId}` })
    },
    getPlanList() {
      this.patientIndex = sessionStorage.getItem('patientIndex')
      this.$api.get(`/v1/webconsole/solution/list/type_group/${this.patientIndex}`).then((res) => {
        if (res.data && res.data.data) {
          this.planList = res.data.data
          for (let i in this.planList) {
            this.planList[i].forEach((element) => {
              if (element.id === this.$route.query.id) {
                element.isShow = true
                this.solutionInfo = element
              } else {
                element.isShow = false
              }
              element.label = element.typeName + '(' + element.createAt.substr(0, 10) + ')'
            })
          }
          if (this.$route.query.idx) {
            this.activeName = String(this.$route.query.idx)
            if (this.$route.query.id) {
              this.solutionId = this.$route.query.id
            }
          } else if (this.$route.path === '/Treatment') {
            this.activeName = '患者信息'
            this.solutionId = ''
          }
        }
      })
    },
    getPatientInfo() {
      this.patientIndex = sessionStorage.getItem('patientIndex')
      this.$api.get(`/v1/webconsole/patientCenter/patient/${this.patientIndex}`).then((res) => {
        this.patientInfo = res.data.data
        this.patientNewInfo = { ...this.patientInfo, patientIndex: this.patientInfo.patientsIndex }
      })
    },
    getModule() {
      this.$api.get('/v1/webconsole/solution/disease/modules').then((res) => {
        this.moduleList = res.data.data
      })
    },
    // addFollow() {
    //   if (!this.patientIndex) return
    //   this.$refs.AddFollowDialog.Show()
    // },
    // _followDialogSave() {
    //   this.$api
    //     .post(`/v1/webconsole/solution/followup/add/${this.patientIndex}`, val)
    //     .then((res) => {
    //       if (res.data.status === 200) {
    //         this.$message.success('添加成功！')
    //       }
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    // },
    goto_image() {
      if (!this.patientInfo.patientsID) return this.$message.warning('当前患者信息不存在')
      const { href } = this.$router.resolve({
        path: `/dicomplayer?patientsID=${this.patientInfo.patientsID}`
      })
      window.open(href, '_blank')
    }
  }
}
</script>
<style lang="less">
.my-label {
  min-width: 120px;
  height: 30px;
  line-height: 30px;
}
</style>
<style scoped lang="less">
.header {
  height: 56px;
  display: flex;
  align-items: center;
  background: #344f7c;
  color: #fff;
  justify-content: space-between;
  background: #fff;
  color: #000;
  border-bottom: 1px solid #eee;
  margin-bottom: -1px;
  .info-button {
    margin-left: 10px;
    margin-right: 20px;
  }
  .info {
    padding-left: 20px;
    display: flex;
    .info-above {
      line-height: 3rem;
      display: flex;
      span {
        margin-left: 5px;
      }
    }

    .user {
      text-align: justify;
      display: flex;
      align-items: center;
      .el-avatar {
        margin-right: 10px;
      }
      .user-avator {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .user-avator img {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}
.block {
  height: calc(100vh - 56px);
  /deep/.el-tabs {
    height: calc(100vh - 56px);
    .info-label {
      padding-left: 20px;
    }
    .el-tabs__header {
      height: 40px;
      line-height: 40px;
      margin: 0;

      .el-dropdown {
        color: #000;
        font-size: 16px;
        font-weight: bold;
      }

      .el-tabs__nav-wrap::after {
        height: 0;
      }
    }
  }
  /deep/.el-tabs--card > .el-tabs__header {
    margin: 0;
  }

  /deep/.el-tabs__item {
    font-size: 1rem;
    font-weight: bold;
    height: 38px;
    line-height: 38px;
  }
  /deep/.el-tabs__item.is-active {
    color: #75affd;
  }

  /deep/.el-tabs__content {
    height: calc(100vh - 97px);

    .el-tab-pane {
      height: 100%;
      .list-main {
        height: 100%;
        background: #f2f2f2;
      }
    }
  }
}
</style>
