<style scoped lang="less">
.full {
  padding: 0;
  margin: 0;
}
.home-content {
  padding: 10px;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  .el-container {
    border: 1px solid #ccc;
    margin: -1px;
    border-radius: 8px;
  }
}
.home-menu {
  min-width: 180px;
  background: #fff;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  .el-menu {
    height: 100%;
  }
}

.home-menu::-webkit-scrollbar {
  display: none;
}

.home-main {
  height: 100%;
  background: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.home-footer {
  background: #ccc;
  text-align: center;
  font-size: 12px;
}
</style>
<template>
  <el-container class="home-content">
    <el-container>
      <el-aside class="home-menu" width="auto">
        <Menu :info="solutionInfo" :averageNum="averageNum" />
      </el-aside>
      <el-main class="full home-main">
        <router-view @updateProjectNum="getAverageNum" :info="solutionInfo" :infoId="solutionId" />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Menu from './components/Menu.vue'

export default {
  name: 'DetailSolution',
  components: {
    Menu
  },
  props: {
    solutionInfo: {
      type: Object,
      default: () => {}
    },
    solutionId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      averageNum: 0
    }
  },
  watch: {},
  beforeCreate() {
    var code = localStorage.getItem('userCode')
    if (code == undefined) {
      var url = 'http://' + location.host
      url += '/#/'
      alert('请先登录')
      window.open(url, '_self')
    }
  },
  created() {},
  mounted() {},
  methods: {
    getAverageNum(val) {
      this.averageNum = val
    }
  }
}
</script>
