<style scoped lang="less">
.timelint-content {
  width: 100%;
  height: 6.5rem;
  //   color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.line {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  overflow-x: auto;
  overflow-y: hidden;
  .line-item {
    width: 10rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .line-content {
      height: 22px;
      flex-grow: 1;
      line-height: 2rem;
      text-align: center;
    }
    .line-icon {
      height: 1rem;
      display: flex;
      align-items: center;
      .line-icon-front-off {
        height: 0.1rem;
        width: 4.5rem;
        // background: #344f7c;
      }
      .line-icon-front {
        height: 0.1rem;
        width: 4.5rem;
        background: #ccc;
      }
      .line-icon-middle {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background: #3879af;
      }
      .line-icon-behind-off {
        height: 0.1rem;
        width: 4.5rem;
        // background: #344f7c;
      }
      .line-icon-behind {
        height: 0.1rem;
        width: 4.5rem;
        background: #ccc;
      }
    }
    .line-time {
      height: 22px;
      flex-grow: 1;
      line-height: 2rem;
      text-align: center;
    }
  }
}

.line::-webkit-scrollbar {
  //滚动条整体样式
  width: 10px;
  height: 10px;
}
.line::-webkit-scrollbar-thumb {
  //滚动条小方块样式
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background: #b5becb;
}
.line::-webkit-scrollbar-track {
  //滚动条滚动轴样式
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background: #ededed;
}
</style>

<template>
  <el-card class="box-card">
    <div class="timelint-content">
      <div class="line">
        <div class="line-item" v-for="(item, index) in timeline" :key="index">
          <div class="line-content">{{ item.title }}</div>
          <div class="line-icon">
            <div class="line-icon-front-off" v-if="index == 0"></div>
            <div class="line-icon-front" v-else></div>
            <div class="line-icon-middle"></div>
            <div class="line-icon-behind-off" v-if="index === timeline.length - 1"></div>
            <div class="line-icon-behind" v-else></div>
          </div>
          <div class="line-time">{{ item.time?.substr(0, 10) }}</div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
var parseDate = function (datetime) {
  return datetime < 10 ? '0' + datetime : datetime
}
export default {
  name: 'TimeLine',
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      date: new Date(),
      timeline: []
    }
  },
  computed: {},
  watch: {
    info: {
      handler(val) {
        if (val && val.patientsIndex) {
          this.getTimeline()
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  filters: {
    formateTime: function (val) {
      var date = new Date(val)
      var year = date.getFullYear()
      var month = parseDate(date.getMonth() + 1)
      var day = parseDate(date.getDate())
      var hours = parseDate(date.getHours())
      var min = parseDate(date.getMinutes())
      var sec = parseDate(date.getSeconds())
      // var week = date.getDay();
      return year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec + ' '
    }
  },
  methods: {
    getTimeline() {
      this.$api.post(`/v1/webconsole/solution/timeline/${this.info.patientsIndex}`).then((res) => {
        this.timeline = res.data.data
      })
    }
  }
}
</script>
