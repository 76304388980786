<style lang="less" scoped>
.top-item-row {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    width: 120px;
    white-space: nowrap;
  }
  .el-input {
    width: 340px;
    /deep/ .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
  .el-textarea {
    width: 340px;
  }
  .el-select {
    width: 340px;
  }
  .el-cascader {
    width: 340px;
  }
}
/deep/.dialog-content__mini {
  min-width: 42rem;
}
.dialog-main {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="新增治疗方案" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialog-main">
      <!-- <div class="top-item-row">
        <span>主诉：</span>
        <el-input type="textarea" :rows="2" v-model="solutionInfo.chiefComplaint"></el-input>
      </div>
      <div class="top-item-row">
        <span>合并症：</span>
        <el-input type="textarea" :rows="2" v-model="solutionInfo.complication"></el-input>
      </div>
      <div class="top-item-row">
        <span>症状体征：</span>
        <el-input
          type="textarea"
          :rows="2"
          v-model="solutionInfo.firstDiagnosisSymptomSign"
        ></el-input>
      </div> -->
      <div class="top-item-row">
        <span>方案类型：</span>
        <el-cascader
          v-model="typeValue"
          :options="options"
          :props="{ expandTrigger: 'hover' }"
          @change="handleChange"
        ></el-cascader>
      </div>
      <!-- <div class="top-item-row">
        <span>方案详情：</span>
        <el-input type="textarea" :rows="2" v-model="solutionInfo.disposeTitle"></el-input>
      </div> -->
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'

import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'addSolutionDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      solutionInfo: {
        patientIndex: null,
        diseaseModule: null,
        firstDiagnosisSymptomSign: '',
        complication: '',
        chiefComplaint: '',
        typeName: '',
        typeCategory: '',
        disposeTitle: ''
      },
      typeValue: [],
      options: []
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    getSolutionType(id) {
      this.$api.get(`/v1/webconsole/solution/disease/solutionType/${id}`).then((res) => {
        if (res.data && res.data.data) {
          const cascaderData = {}
          // 遍历原始数组，按照 typeCategory 进行分组
          res.data.data.forEach((item) => {
            if (!cascaderData[item.typeCategory]) {
              cascaderData[item.typeCategory] = []
            }
            cascaderData[item.typeCategory].push({
              label: item.typeName,
              value: item.typeName
            })
          })
          // 将分组后的数据转换为级联选择框需要的格式
          const cascaderOptions = Object.keys(cascaderData).map((category) => ({
            label: category,
            value: category,
            children: cascaderData[category]
          }))
          this.options = cascaderOptions
        }
      })
    },

    handleChange() {
      this.solutionInfo.typeCategory = this.typeValue[0]
      this.solutionInfo.typeName = this.typeValue[1]
    },

    Show(val) {
      if (val) {
        this.solutionInfo.diseaseModule = val.id
        this.solutionInfo.patientIndex = sessionStorage.getItem('patientIndex')
        this.getSolutionType(val.id)
      }
      this.isShow = true
    },

    Save() {
      let data = {
        patientsIndex: this.solutionInfo.patientIndex,
        firstDiagnosisSymptomSign: this.solutionInfo.firstDiagnosisSymptomSign,
        diseaseModule: this.solutionInfo.diseaseModule,
        complication: this.solutionInfo.complication,
        chiefComplaint: this.solutionInfo.chiefComplaint,
        typeName: this.solutionInfo.typeName,
        typeCategory: this.solutionInfo.typeCategory,
        disposeTitle: this.solutionInfo.disposeTitle
      }
      this.$api
        .post(`/v1/webconsole/solution/add/${this.solutionInfo.diseaseModule}`, data)
        .then((res) => {
          if (res.data && res.data.data) {
            console.log(res.data.data)
            this.$message.success('新增成功')
            this.$router.push({
              path: `/Treatment/Info?idx=${res.data.data.typeCategory}&id=${res.data.data.id}`
            })
            this.$emit('save')
          } else {
            this.$message.warning('新增失败')
          }
        })
        .finally(() => {
          this.Close()
        })
    },
    Close() {
      this.options = []
      this.typeValue = []
      this.solutionInfo = {
        patientIndex: null,
        diseaseModule: null,
        firstDiagnosisSymptomSign: '',
        complication: '',
        chiefComplaint: '',
        typeName: '',
        typeCategory: '',
        disposeTitle: ''
      }
      this.isShow = false
    }
  }
}
</script>
