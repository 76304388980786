<style lang="less" scoped>
.top-item-row {
  margin: 10px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    width: 120px;
    white-space: nowrap;
  }
  .el-input {
    width: 300px;
    /deep/ .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
  .el-select {
    width: 300px;
  }
  .el-radio-group {
    width: 300px;
  }
  .el-cascader {
    width: 300px;
  }
}
/deep/.dialog-content__mini {
  min-width: 35rem;
}
.dialog-main {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>

<template>
  <CmDialogBorder
    size="mini"
    :zIndex="zIndex"
    :title="title + '患者既往手术'"
    v-show="isShow"
    @close="Close"
  >
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialog-main">
      <div class="top-item-row">
        <span>手术名称：</span>
        <el-input v-model="hisInfo.operation"></el-input>
      </div>
      <div class="top-item-row">
        <span>手术日期：</span>
        <el-date-picker
          v-model="hisInfo.operationDate"
          type="date"
          align="center"
          size="mini"
          placeholder="手术日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'

import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../../../plugins/clone'

export default {
  name: 'addOperationHisDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      patientIndex: null,
      title: '',
      hisInfo: {
        patientIndex: null,
        operation: '',
        operationDate: ''
      }
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    Show(val) {
      if (val) {
        this.title = '编辑'
        this.hisInfo = deepClone(val)
      }
      this.patientIndex = sessionStorage.getItem('patientIndex')

      this.isShow = true
    },

    Save() {
      if (!this.hisInfo.operation) {
        return this.$message.warning('请填写既往手术名称')
      }
      if (!this.hisInfo.operationDate) {
        return this.$message.warning('请填写既往手术日期')
      }
      let data = {
        operation: this.hisInfo.operation,
        operationDate: this.hisInfo.operationDate
      }
      if (this.hisInfo.patientIndex) {
        data.patientIndex = this.hisInfo.patientIndex
        data.uuid = this.hisInfo.uuid
        this.$api
          .post(`/v1/webconsole/patientCenter/operationHistory/update`, data)
          .then((res) => {
            if (res.data && res.data.data) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$emit('save')
            }
          })
          .finally(() => {
            this.Close()
          })
      } else {
        this.$api
          .post(`/v1/webconsole/patientCenter/operationHistory/add/${this.patientIndex}`, data)
          .then((res) => {
            if (res.data && res.data.data) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$emit('save')
            }
          })
          .finally(() => {
            this.Close()
          })
      }
    },
    Close() {
      this.title = '新增'
      this.hisInfo = {
        patientIndex: null,
        operation: '',
        operationDate: ''
      }
      this.isShow = false
    }
  }
}
</script>
